import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AuthContext } from "../context/context";
import {
    ChevronRightIcon,
    InformationCircleIcon,
} from "@heroicons/react/solid";
import axiosInstance from "../../client/index.js";
import { Loading } from "../layout/Loading.js";
import Cart from "../cart/cart";
import { Error } from "../form/Error";
import { Info } from "../kurzy/Info";

export const CheckOut = (props) => {
    const authContext = useContext(AuthContext);
    const cart = props.cart;

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const userData = JSON.parse(localStorage.getItem("userDataStatus"));

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
    });

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const res = await axiosInstance.post("wizard/cartSend/", {
                cartId: props.cartId,
                data: userData?.data?.data,
                notes: data,
            });
            if (res.data) {
                props.updateCartId();
                history.push("/prihlaska-odeslana");
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };

    useEffect(
        () => {
            //updateAction(false)
        },
        // eslint-disable-next-line
        []
    );

    function infoModal(text, title) {
        let content;
        content = props.info[text];

        props.setModalContent({
            component: Info,
            params: {
                title: title,
                text: content,
            },
        });
        props.setModalIsOpen(true);
    }

    return (
        <>
            <div className="grid  lg:gap-2">
                <div>
                    <div className="bg-gray-100 rounded-md p-5 max-w-sm">
                        <p className="font-bold">
                            {userData?.data?.data?.fname}{" "}
                            {userData?.data?.data?.surname}
                        </p>
                        <p>{userData?.data?.data?.street}</p>
                        <p>
                            {userData?.data?.data?.zip}{" "}
                            {userData?.data?.data?.city}
                        </p>

                        <p className="mt-5">{userData?.data?.data?.tel}</p>
                        <p>{userData?.data?.data?.email}</p>
                    </div>
                </div>
                <div>
                    <Cart {...props} summary={true} />
                </div>
            </div>

            {cart && cart.items?.length > 0 && (
                <div className="text-2xl text-right py-4 ">
                    Celkem:{" "}
                    <span className="font-medium">
                        {parseInt(cart?.cart?.sum).toLocaleString("cs")} Kč
                    </span>
                </div>
            )}
            {userData && (
                <form
                    key={1}
                    onSubmit={handleSubmit(onSubmit)}
                    className={"space-y-8  py-2 "}
                >
                    <div className="bg-blue-600 bg-opacity-20 rounded-md pb-3 ">
                        <div className="p-4  font-medium text-blue-800 bg-blue-600 bg-opacity-20 rounded-t-md">
                            Pomozte nám, prozraďte něco o sobě
                        </div>
                        <div className=" grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3 relative px-4 py-2">
                            <div className="  sm:border-gray-200 ">
                                <label
                                    htmlFor="jak"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    Jak jste se o festivalu dozvěděli?
                                </label>
                                <div className="mt-1  ">
                                    <input
                                        id="jak"
                                        name="jak"
                                        type="text"
                                        {...register("jak")}
                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="  sm:border-gray-200 ">
                                <label
                                    htmlFor="kolik"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    Po kolikáté se festivalu účastníte?
                                </label>
                                <div className="mt-1 ">
                                    <input
                                        id="kolik"
                                        name="kolik"
                                        {...register("kolik")}
                                        type="text"
                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="  sm:border-gray-200 ">
                                <label
                                    htmlFor="year"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    Rok narození
                                </label>
                                <div className="mt-1  ">
                                    <input
                                        id="year"
                                        name="rok"
                                        type="text"
                                        {...register("rok")}
                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                            htmlFor="about"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                            Poznámka
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <textarea
                                id="note"
                                name="note"
                                rows={3}
                                className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
                                defaultValue={""}
                                {...register("onote")}
                            />
                        </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4">
                        <div className="  pb-3 lg:pb-0">
                            <button
                                type="button"
                                onClick={() =>
                                    infoModal(
                                        "podminky-platba",
                                        "Platební a stornovací podmínky"
                                    )
                                }
                                className="   bg-gray-100 border border-gray-300 rounded-md shadow-sm px-4 py-1 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                <InformationCircleIcon
                                    className="mr-3 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                                Zobrazit podmínky
                            </button>
                        </div>
                        <div className="max-w-lg col-span-2">
                            <div className="flex justify-end"></div>
                            <div className="flex">
                                <div className="flex items-center h-5">
                                    <input
                                        id="comments"
                                        name="comments"
                                        type="checkbox"
                                        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-600 rounded"
                                        {...register("souhlas", {
                                            required: true,
                                        })}
                                    />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor="comments">
                                        <span className="font-medium text-gray-700">
                                            Souhlasím s podmínkami kurzů
                                            festivalu ProART{" "}
                                            <span className="text-red-600 inline-flex items-center">
                                                <svg
                                                    className="w-3 "
                                                    fill="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="m21.91 14.835-4.91-2.835 4.91-2.835c.478-.276.642-.888.366-1.366l-1.5-2.598c-.276-.478-.887-.642-1.366-.366l-4.91 2.835v-5.67c0-.552-.448-1-1-1h-3c-.552 0-1 .448-1 1v5.67l-4.91-2.835c-.479-.276-1.09-.112-1.366.366l-1.5 2.598c-.276.478-.113 1.09.366 1.366l4.91 2.835-4.91 2.835c-.478.276-.642.888-.366 1.366l1.5 2.598c.276.478.888.642 1.366.366l4.91-2.835v5.67c0 .552.448 1 1 1h3c.552 0 1-.448 1-1v-5.67l4.91 2.835c.478.276 1.09.112 1.366-.366l1.5-2.598c.276-.478.113-1.09-.366-1.366z" />
                                                </svg>
                                            </span>
                                        </span>
                                        <p className="text-gray-500 text-xs">
                                            V souladu se zákonem o ochraně
                                            osobních údajů a GDPR SOUHLASÍM se
                                            zpracováním emailové adresy pro
                                            vnitřní potřeby Spolku ProART a jeho
                                            informační a marketingové účely..
                                        </p>
                                    </label>
                                </div>
                            </div>
                            {errors.souhlas && (
                                <Error text="Potvrďte prosím souhlas s podmínkami" />
                            )}
                        </div>
                        <div></div>
                        {/* <div className="max-w-lg col-span-2 mt-2">
                            <div className="flex justify-end"></div>
                            <div className="flex">
                                <div className="flex items-center h-5">
                                    <input
                                        id="foto"
                                        name="foto"
                                        type="checkbox"
                                        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-600 rounded"
                                        {...register("foto")}
                                    />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor="foto">
                                        <span className="font-medium text-gray-700">
                                            Souhlasím s pořizováním foto a video
                                            dokumentace mé osoby
                                        </span>
                                        <p className="text-gray-500 text-xs">
                                            Tato dokumentace slouží pro
                                            propagační účely spolku ProART.
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="sm:col-span-2 mt-3 flex justify-end">
                        <button className="flex items-center   text-green-100 h-12 text-lg font-medium  px-5 py-1 focus:outline-none  space-x-4 bg-green-600 rounded-md border border-gray-200 hover:bg-green-700">
                            <span>Odeslat přihlášku</span>
                            <ChevronRightIcon className="w-8 " />
                        </button>
                    </div>
                </form>
            )}

            <Loading loading={loading} />
        </>
    );
};
