
import React, { useState, useEffect, useRef } from "react"; 
import { NavLink } from "react-router-dom";
import axiosInstance from "../../client"; 
import { useForm } from "react-hook-form"; 

export const MiniCart = (props) => {
    const { register, handleSubmit } = useForm();
    const onSubmit = data => {};     
   
    const cart = props.cart?.cart;
      
    // useEffect(() => {
    //     cartListUpdate();        
    // }
    //   // eslint-disable-next-line
    // , []);
      
      return ( 
        <> 
         <NavLink to={'/kosik'}>
            <div className={"border-2 rounded-md  border-opacity-50 hover:border-opacity-80  px-3 py-2 flex items-center space-x-3 relative " 
            + (cart?.count > 0 ? "bg-blue-700 text-white border-blue-400 hover:bg-blue-600 " : "border-gray-400 text-gray-500 hover:bg-gray-300 ") } >
                
                <svg id="Capa_1" className="w-5" fill="currentColor"  viewBox="0 0 512 512"  ><g><path d="m497 181h-52.791l-115.496-144.37c-5.174-6.467-14.613-7.518-21.083-2.342-6.469 5.175-7.518 14.614-2.342 21.083l100.503 125.629h-299.582l100.504-125.629c5.175-6.469 4.126-15.909-2.342-21.083-6.47-5.176-15.909-4.126-21.083 2.342l-115.497 144.37h-52.791c-8.284 0-15 6.716-15 15v30c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15v-30c0-8.284-6.716-15-15-15z"/><path d="m90.577 470.121c1.84 6.44 7.726 10.879 14.423 10.879h302c6.697 0 12.583-4.439 14.423-10.879l56.891-199.121h-444.628zm234.127-66.586 15-90c1.362-8.172 9.09-13.696 17.262-12.33 8.172 1.362 13.692 9.09 12.33 17.262l-15 90c-1.223 7.337-7.578 12.537-14.778 12.537-.819 0-1.649-.067-2.484-.207-8.172-1.362-13.692-9.091-12.33-17.262zm-83.704-87.535c0-8.284 6.716-15 15-15s15 6.716 15 15v90c0 8.284-6.716 15-15 15s-15-6.716-15-15zm-84.966-14.795c8.173-1.361 15.9 4.158 17.262 12.33l15 90c1.362 8.171-4.158 15.9-12.33 17.262-.834.139-1.665.207-2.484.207-7.201 0-13.555-5.199-14.778-12.537l-15-90c-1.362-8.172 4.158-15.901 12.33-17.262z"/></g></svg>
                {cart?.count > 0 && <div className="absolute top-0 font-bold -right-2 -mt-2  text-xs w-5 h-5 bg-red-500 text-white rounded-full flex items-center justify-center"><span>{cart?.count}</span></div>}

               <div> {cart?.sum > 0 ? <><span className="font-bold">{parseInt(cart.sum).toLocaleString('cs')} Kč</span></> : "Košík"}</div>
            </div>
         </NavLink>    

        </>
      )

   }