import { Menu,Transition } from '@headlessui/react'; 
import { AuthContext } from "../context/context";
import { useContext, useState, useEffect } from 'react';
import { UserIcon } from '@heroicons/react/outline';

export default function User({setUser}) { 
    
    const authContext = useContext(AuthContext);
    const logoutHandler = () => { 
        localStorage.removeItem('userDataStatus');
        localStorage.removeItem('userData');
        authContext.logout();
    };  
  
    const [userData, setUserData] = useState();

    useEffect(() => { 
        setUserData(JSON.parse(localStorage.getItem('userDataStatus')));
      }
      // eslint-disable-next-line 
    , [authContext]);

    const user = userData?.data
 

    return(
        <>
        {authContext.isLoggedIn &&
            <Menu>
                        {({ open }) => (
                            <>
                        
                            {user &&
                            <Menu.Button className={'py-1 border-2 text-gray-600 px-4 rounded-md text-left border-gray-400 border-opacity-50 mr-2  focus:outline-none transition duration-150 ease-in-out  hover:bg-gray-300  ' +  
                             (open ? ' bg-gray-300   '  : 'text-gray-200')} id="user-menu">
                            <div className="flex items-center">
                                <div className="">
                                {user?.img ? (
                                        <img src={user.img} alt="" className="h-8 w-8 fill-current border-2  rounded-full"  />
                                    ) : (
                                        <UserIcon className="h-8 p-1 text-gray-500" />
                                    )
                                    }
                                </div>
                                 
                            </div>
                            </Menu.Button>
                            
                            }
                            <Transition className="absolute right-0 top-18 "
                            show={open}
                            enter="transition ease-out duration-100 transform"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="transition ease-in duration-75 transform"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                            >
                            <Menu.Items
                            static
                            className=" w-40 lg:w-64 mb-2 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                            >
                            
                            <div className="px-4 py-3">
                                <p className="text-sm leading-5">Přihlášený uživatel</p>
                                <p className="text-sm font-medium leading-5  truncate">
                                {user && user.email}
                                </p>
                            </div>
 
                            <div className="py-1">
                                <Menu.Item>
                                {({ active }) => (
                                    <button
                                   
                                    className={`${
                                        active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-800"
                                    } flex w-full px-4 py-2 text-sm leading-5 text-left`}
                                    onClick={logoutHandler}
                                    >
                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
                                            viewBox="0 0 511.996 511.996" className="h-5 w-5 mr-2 inline" fill="currentColor" stroke="none">
                                                <path d="M349.85,62.196c-10.797-4.717-23.373,0.212-28.09,11.009c-4.717,10.797,0.212,23.373,11.009,28.09
                                                        c69.412,30.324,115.228,98.977,115.228,176.035c0,106.034-85.972,192-192,192c-106.042,0-192-85.958-192-192
                                                        c0-77.041,45.8-145.694,115.192-176.038c10.795-4.72,15.72-17.298,10.999-28.093c-4.72-10.795-17.298-15.72-28.093-10.999
                                                        C77.306,99.275,21.331,183.181,21.331,277.329c0,129.606,105.061,234.667,234.667,234.667
                                                        c129.592,0,234.667-105.068,234.667-234.667C490.665,183.159,434.667,99.249,349.85,62.196z"/>
                                                <path d="M255.989,234.667c11.782,0,21.333-9.551,21.333-21.333v-192C277.323,9.551,267.771,0,255.989,0
                                                        c-11.782,0-21.333,9.551-21.333,21.333v192C234.656,225.115,244.207,234.667,255.989,234.667z"/>
                                        </svg> 
                                    Odhlásit
                                    </button>
                                )}
                                </Menu.Item>
                            </div>
                            </Menu.Items>
                          </Transition>
                          </>
                          )}
                          
                        </Menu>
                }
        </>
    )
}