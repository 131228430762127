import { ChevronRightIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { Fragment, useState, useEffect  } from 'react'
import { NavLink } from 'react-router-dom'
import { ModalLayout } from '../layout/modal'
import { Transition } from '@headlessui/react'
import Cart from './cart'

export default function CartModal(props) {
   const cart = props.cart
   const lastCartItem = props.lastCartItem

   const [toast,setToast] = useState(false);

   useEffect(() => { 
    setToast(true) 
    let timer1 = setTimeout(() => setToast(false), 4000);

    return () => {
        clearTimeout(timer1);
    };
    // eslint-disable-next-line
    }, [])
 

  return (
      <>
         <div className=" absolute top-0 right-2 lg:top-2 lg:right-4  w-full max-w-md   pb-2 sm:pb-5 ">
          <Transition
            show={toast}  
          >
                       
                       <Transition.Child
                    className="inline-block align-bottom  rounded-lg mx-2 text-left overflow-hidden transform transition-all lg:mb-10 sm:align-top sm:max-w-4xl w-full "
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    
                    <div className="w-full p-1">
                    <div className="p-2 rounded-lg bg-blue-600 shadow-lg sm:p-3">
                        <div className="flex items-center justify-between flex-wrap">
                            <div className="w-0 flex-1 flex items-center">
                            <span className="flex p-2 rounded-lg bg-blue-800">
                                <InformationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </span>
                            <p className="ml-3 font-medium text-white truncate">
                                <span >Položka přidána do košíku</span>
                            </p>
                            </div>
                            
                        </div>
                        </div>
                    </div>
                </Transition.Child>
                
                
                
                
          </Transition>
            </div>
       <ModalLayout title={'Nákupní košík'}>
        <div className="  mx-auto">
            <Cart {...props} lastCartItem={lastCartItem}  />
        
            {cart && cart.items?.length > 0 &&
                <div className="text-2xl text-right py-4 ">Celkem: <span className="font-medium">{parseInt(cart?.cart?.sum).toLocaleString('cs')} Kč</span></div>  
            }
                <div className="grid justify-end">

                {cart && cart.items?.length > 0 
                ?
                    <div className=" py-4 flex items-center space-x-4">
                        <button type="button" onClick={() => props.setModalIsOpen(false)} className="flex items-center   text-green-100 h-12  px-5 py-1 focus:outline-none  space-x-4 bg-green-600 rounded-md border border-gray-200 hover:bg-green-700">
                            <span className="truncate">Přidat další kurz</span>
                            <ChevronRightIcon className="w-8 " /> 
                        </button>
                        <NavLink type="button" to={'/kosik/2'} onClick={() => props.setModalIsOpen(false)} className="flex items-center   text-gray-600 h-12  px-5 py-1 focus:outline-none  space-x-4 bg-gray-100 rounded-md border border-gray-200 hover:bg-gray-200">
                            <span>Objednat</span>
                            <ChevronRightIcon className="w-8 " /> 
                        </NavLink>
                    </div>
                : 
                    <div className=" py-4 flex items-center space-x-4">
                        <button type="button" onClick={() => props.setModalIsOpen(false)} className="flex items-center   text-gray-600 h-12  px-5 py-1 focus:outline-none  space-x-4 bg-gray-100 rounded-md border border-gray-200 hover:bg-gray-200">
                            <span className="truncate">Zavřít</span>
                            <ChevronRightIcon className="w-8 " /> 
                        </button>
                    </div>
                }
                </div>
        </div>  
        </ModalLayout>
     </>
  )
}