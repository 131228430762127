import Home from './components/layout/home.js';
import React, { useState, useEffect, useCallback } from "react";
import { useHistory, Switch, Route, Redirect,BrowserRouter } from "react-router-dom";
import Layout from "./components/layout"; 
import KurzyHome from "./components/kurzy/kurzyHome";  
import './App.css';
import axiosInstance from "./client/index.js";
import Wizard from './components/wizard/Wizard.js'; 
import {AuthContext} from './components/context/context';
import Sent from './components/layout/Sent.js';
import { data } from 'autoprefixer';

let logoutTimer;

function App() {
  const [token, setToken] = useState(null);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [cart, setCart] = useState();
  const [cartId, setCartId] = useState(localStorage.getItem('cartId'));
  const [kurzy, setKurzy] = useState([]);  
  const [balicky, setBalicky] = useState([]);  
  const [cities, setCities] = useState([]);  
  const [info, setInfo] = useState();  
  const [img, setImg] = useState();  

  const login = useCallback((token, expirationTime) => {
    setToken(token);
    const expiration =
      expirationTime || new Date(new Date().getTime() + 1000 * 60 * 60);
    setTokenExpirationDate(expiration);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        token,
        expirationTime: expiration.toISOString()
      })
    );
  }, []);
 
  const logout = useCallback(() => {
    setToken(null);
    localStorage.removeItem("userData");
  }, []);

  function updateCartId() {
    let cartHash
    cartHash = 'cart_' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    localStorage.setItem("cartId", cartHash);
    setCartId(cartHash);
  }

  useEffect(() => {  
      if(!cartId) {
        updateCartId() 
      }
      async function getItems() {
        try {
          const res = await axiosInstance.post('cart/items/' + cartId + '/')
          if(res.data.cart) {
            setCart(res.data)
          }
        } catch(err) {
            console.log(err);
        }
      }
      async function getCourses() {
        try {
          const res = await axiosInstance.post('terminy/')
          if(res.data) {
            setCities(res.data.cities)
            setKurzy(res.data.courses)
            setBalicky(res.data.packs)
            setInfo(res.data.info)
            setImg(res.data.img)
          }
        } catch(err) {
            console.log(err);
        } 
      }
      getItems();
      getCourses();
      // eslint-disable-next-line
    }, [cartId]); 

    useEffect(() => {
      const storedData = JSON.parse(localStorage.getItem("userData"));
      if (
        storedData &&
        storedData.token &&
        new Date(storedData.expirationTime) > new Date()
      ) {
        login(storedData.token, new Date(storedData.expirationTime));
      }
    }, [login]);

    useEffect(() => {
      if (token && tokenExpirationDate) {
        const remainingTime =
          tokenExpirationDate.getTime() - new Date().getTime();
        logoutTimer = setTimeout(logout, remainingTime);
      } else {
        clearTimeout(logoutTimer);
      }
    }, [token, logout, tokenExpirationDate]);

      
  return (
    <>
    <AuthContext.Provider value={{
        isLoggedIn: !!token,
        token: token, 
        login: login,
        logout: logout
      }}>    
      <BrowserRouter>
        <Switch>
              <Route exact path="/" render={(props) => <Layout {...props}  component={Home} cartId={cartId} updateCartId={updateCartId} setCart={setCart} cart={cart} cities={cities} balicky={balicky} logout={logout} info={info} img={img} />} />
              <Route path="/kurzy/:mesto" render={(props) => <Layout {...props} component={KurzyHome} cartId={cartId} updateCartId={updateCartId} setCart={setCart} cart={cart} kurzy={kurzy} cities={cities} balicky={balicky} logout={logout} info={info}/>} /> 
              <Route exact path="/kosik" render={(props) => <Layout {...props} component={Wizard} cartId={cartId} updateCartId={updateCartId} setCart={setCart} cart={cart} cities={cities}  balicky={balicky} logout={logout} info={info}/>}  /> 
              <Route path="/kosik/:step" render={(props) => <Layout {...props} component={Wizard} cartId={cartId} updateCartId={updateCartId} setCart={setCart} cart={cart} cities={cities}  balicky={balicky} logout={logout} info={info}/>}  /> 
              <Route path="/prihlaska-odeslana" render={(props) => <Layout {...props} component={Sent} cartId={cartId} updateCartId={updateCartId} setCart={setCart} cart={cart} cities={cities}  balicky={balicky} logout={logout} info={info}/>}  /> 
        </Switch>
      </BrowserRouter>
    </AuthContext.Provider>
    </>
  );
}

export default App;
