import { useState, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { format } from "date-fns";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export const PackSelect = (props) => {
    const [selectedItem, updateItem] = useState();
    const packItem = props.packItem;

    function setItem(e) {
        updateItem(e);
        props.setValue(props.field, packItem.terminy[e]?.mod_turnusy_id);
        props.clearErrors(props.field);
    }

    const options = { month: "numeric", day: "numeric" };

    const Termin = (props) => {
        const course = props.course;
        const terminOd =
            course?.mod_terminy_od &&
            format(new Date(course?.mod_terminy_od), "d. M.");
        const terminDo =
            course?.mod_terminy_do &&
            format(new Date(course?.mod_terminy_do), "d. M. y");

        return terminOd + " - " + terminDo;
    };

    return (
        <Listbox value={selectedItem} onChange={(e) => setItem(e)}>
            {({ open }) => (
                <>
                    <div className=" relative  mt-1 ">
                        <Listbox.Button
                            className={() =>
                                classNames(
                                    " border rounded-md  py-2 px-4   w-full  pl-3 pr-10 text-left bg-white shadow-sm cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-blue-500 focus-visible:ring-offset-blue-300 focus-visible:ring-offset-2 focus-visible:border-blue-500 ",
                                    open
                                        ? "border-blue-500 ring-2 "
                                        : "border-gray-300 "
                                )
                            }
                        >
                            <span className="block truncate">
                                {packItem.terminy[selectedItem] ? (
                                    <span className="flex justify-between">
                                        <span>
                                            {
                                                packItem.terminy[selectedItem]
                                                    ?.mod_kurzy_nazev
                                            }
                                        </span>{" "}
                                        <span>
                                            <Termin
                                                course={
                                                    packItem.terminy[
                                                        selectedItem
                                                    ]
                                                }
                                            />
                                        </span>
                                    </span>
                                ) : (
                                    <span className="opacity-50">
                                        zvolte kurz
                                    </span>
                                )}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <ChevronDownIcon
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>
                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                static
                                className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            >
                                {packItem.terminy &&
                                    packItem.terminy.map((item, i) => (
                                        <Listbox.Option
                                            key={i}
                                            className={({ active }) =>
                                                `${
                                                    active
                                                        ? "text-blue-900 bg-blue-100"
                                                        : "text-gray-900"
                                                }
                            cursor-default select-none relative py-2 pl-10 pr-4`
                                            }
                                            value={i}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span
                                                        className={`${
                                                            selected
                                                                ? "font-medium"
                                                                : "font-normal"
                                                        }  truncate grid lg:flex justify-between`}
                                                    >
                                                        <span>
                                                            {
                                                                item.mod_kurzy_nazev
                                                            }
                                                        </span>{" "}
                                                        <span>
                                                            <Termin
                                                                course={item}
                                                            />
                                                        </span>
                                                    </span>
                                                    {selected ? (
                                                        <span
                                                            className={`${
                                                                active
                                                                    ? "text-blue-600"
                                                                    : "text-blue-600"
                                                            }
                                    absolute inset-y-0 left-0 flex items-center pl-3`}
                                                        >
                                                            <CheckIcon
                                                                className="w-5 h-5"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
};
