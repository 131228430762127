
import React, { useState, useEffect } from "react"; 
import { NavLink, useParams } from "react-router-dom"; 
import { Kurz, Balicek } from "./Kurz";
import { ChevronDownIcon, InformationCircleIcon } from '@heroicons/react/outline'
import { Info } from "./Info";

export default function KurzyHome(props) {
    const {mesto} = useParams();
    const kurzy = props.kurzy;
    const balicky = props.balicky;
    const info = props.info;
    const [kurzyMesto, setKurzyMesto] = useState([]);    
    const [balickyMesto, setBalickyMesto] = useState([]);  
    const [cityData, setCityData] = useState();
    const [loading, setLoading] = useState(false);
     
    function infoModal(text,title) {
      let content
      if(text==='info') {
        content = cityData?.perex
      } else {
        content = props.info[text]
      }
      props.setModalContent({
        component: Info,
        params: {          
          title: title,
          text: content
        }
      }) 
      props.setModalIsOpen(true)
    }
    
    let activeCity
    useEffect(() => {
        setLoading(true);
        setKurzyMesto(props.kurzy[mesto]);      
        setBalickyMesto(props.balicky[mesto]);  
        
        if(props.cities)
        props.cities.map((city,i) => {
          if(mesto==city.alias) {
            activeCity = city.name
            setCityData(city)
          }
        })
    
        activeCity 
        ? props.setTitle(props.mainTitle + activeCity)  
        : props.setTitle(props.mainTitle)  
        setLoading(false);
    }
      // eslint-disable-next-line
    , [mesto,kurzy,balicky]);
     
      
    return (
        <>
        
        <header className="bg-white  ">
            <div className="max-w-7xl mx-auto py-4 px-4">
              <div  >
                <h1 className="text-xl lg:text-3xl  pb-6 leading-6  text-blue-800 grid lg:flex space-y-2 lg:space-y-0 lg:space-x-4"><span className="font-bold">{props.title}</span><span>{cityData?.type}</span></h1>
                
              </div>
              <div>
                <button onClick={() => infoModal('info','Informace k přihlášce')} className=" mr-2 mb-2  bg-gray-100 border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                          <InformationCircleIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                           Informace k přihlášce
                          
                </button>

                <button onClick={() => infoModal('podminky-platba','Platební a stornovací podmínky')} className=" mr-2 mb-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                          <InformationCircleIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                           Podmínky
                          
                </button>


              </div>
              
            </div>

          </header>
          
        <div className="bg-gray-100 lg:bg-white">
        {balickyMesto && (
                    <>
                    <div className="px-6 font-medium font-xl text-gray-700 lg:bg-blue-700 lg:bg-opacity-10 flex space-x-2 py-3 my-2 rounded-md">
                       
                      <span>Zvýhodněné kombinace kurzů</span>
                      </div> 
                    <div className="divide-y divide-gray-200  ">
                    {balickyMesto.map((kurz,kurzIdx) => (
                      <Balicek key={kurzIdx} course={kurz} cartId={props.cartId} setCart={props.setCart} cart={props.cart} setModalContent={props.setModalContent} setModalIsOpen={props.setModalIsOpen} mesto={mesto} />
                    ))}
                  </div> 

                </> 
            )}

           {kurzyMesto ? (
                    <> 
                      <div className="px-6 font-medium font-xl text-gray-700 lg:bg-blue-700 lg:bg-opacity-10  flex space-x-2 py-3 my-2 rounded-md">
                       
                       <span>Kurzy festivalu {cityData?.name}</span>
                      </div>  
                  <div className="divide-y divide-gray-200 border-b ">
                    {kurzyMesto.map((kurz,kurzIdx) => (
                      <Kurz key={kurzIdx} course={kurz} cartId={props.cartId} setCart={props.setCart} cart={props.cart} setModalContent={props.setModalContent} setModalIsOpen={props.setModalIsOpen} mesto={mesto}  />
                    ))}
                  </div>

                </> 
              ) : (
                <>
                    <ul   className="flex flex-1 flex-wrap my-10 py-5">
                        <li  className="h-full w-full text-center flex flex-col justify-center items-center  "> 
                            
                            <span className=" text-lg text-gray-400 mt-10 opacity-50">Žádné kurzy k dispozici</span>
                        </li>
                      </ul>
                </>
              ) }
            {loading && (
                <>
                      <ul   className="flex flex-1 flex-wrap my-10 py-5">
                        <li  className="h-full w-full text-center flex flex-col justify-center items-center  "> 
                            <svg className="animate-spin -ml-1 mr-3  w-10 inline" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg> 
                            <span className=" text-lg text-gray-400 mt-10 opacity-50">Načítání...</span>
                        </li>
                      </ul>
                    </>
              ) 
          }
          </div>

          <div className="text-gray-600 text-sm my-5 mx-auto w-auto flex justify-center"><span>{cityData?.web}</span></div>
        </>
    )
}