import React, { useState, useEffect, Fragment } from "react";  
import { ChevronRightIcon } from '@heroicons/react/outline'
import { NavLink } from "react-router-dom"; 
import { cities } from "../layout"; 
import Steps from "../wizard/Steps";

const steps = [
  { name: 'Odesláno', href: '#', current: false },
]

export default function Sent(props) {
    const [activeTab, setActiveTab] = useState(2)

    useEffect(() => {  
       props.setTitle('Přihláška odeslána')
        // eslint-disable-next-line
      }, []); 
   
        return (
             <> 

<header className="bg-white  ">
            <div className="max-w-3xl mx-auto py-4 px-2">
              <h1 className="text-2xl  pb-6 leading-6 font-semibold text-blue-800">{props.title}</h1>
            </div>
          </header>
 
             <div className="max-w-3xl mx-auto   justify-center  py-4 px-2">

               <p>Potvrzení a platební podklady jsme Vám odeslali e-mailem. Pokud během pár minut nedorazí, zkontrolujte složku s nevyžádanou poštou SPAM.</p>
               <p className="mt-5 font-medium">Děkujeme za přihlášení na ProART Festival.</p>
                 
             </div>
            </>
        )
    }
 