import { ChevronRightIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import Cart from '../cart/cart'
import Userdata from '../cart/Userdata'
import { CheckOut } from './CheckOut'
import Steps from './Steps'
 

const steps = [
    { name: 'Košík', href: '#', current: false },
    { name: 'Kontaktní údaje', href: '#', current: true },
    { name: 'Rekapitulace', href: '#', current: false }, 
]

export default function Wizard(props) {
    let { step } = useParams();
 
    if(!step) {
        step = 1
    }
 
    const cart = props.cart
    const [activeTab, setActiveTab] = useState(step)
    
    useEffect(() => { 
        if(parseInt(step)===1) {
            props.setTitle('Nákupní košík')
        } else if(parseInt(step)===2) {
            props.setTitle('Kontaktní údaje')
        } else {
            props.setTitle('Odeslání přihlášky')
        }
        setActiveTab(parseInt(step))
      }
      // eslint-disable-next-line 
    , [step]);

  return (
      <>
      <header className="bg-white  ">
            <div className="max-w-3xl mx-auto py-4 px-2">
              <h1 className="text-2xl  pb-6 leading-6 font-semibold text-blue-800">{props.title}</h1>
            </div>
          </header>

     <Steps steps={steps} activeTab={activeTab} setActiveTab={setActiveTab} />
    <div className="p-5">
     {activeTab===1 &&
     <>
        <div className="max-w-3xl mx-auto">
            <Cart {...props}  />
        
        {cart && cart.items?.length > 0 && 
        <>
                <div className="text-2xl text-right p-4 ">Celkem: <span className="font-medium">{parseInt(cart?.cart?.sum).toLocaleString('cs')} Kč</span></div>  
            <div className="grid justify-end">


                <div className=" p-4">
                    <NavLink type="button" to={'/kosik/2'} className="flex items-center   text-blue-100 h-12  px-5 py-1 focus:outline-none  space-x-4 bg-blue-600 rounded-md border border-gray-200 hover:bg-blue-700">
                        <span>Pokračovat</span>
                        <ChevronRightIcon className="w-8 " /> 
                    </NavLink>
                </div>
            </div>
            </>
        }
        </div>

        
    </>
     }

     {activeTab===2 && 
     <>
        <div className="max-w-3xl mx-auto">

            <Userdata {...props} cartItems={props.cartItems} cartId={props.cartId} setActiveTab={setActiveTab} />
        </div>
     </>
     }

    {activeTab===3 && 
     <>
        <div className="max-w-3xl mx-auto">

           
            <CheckOut {...props} />
        </div>
     </>
     }

    </div>
     </>
  )
}