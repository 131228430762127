import {ModalLayout} from '../layout/modal'
import { ChevronRightIcon } from '@heroicons/react/solid'

export const Info = (props) => {
    const text = props.text
    const title = props.title

    return (
        <>
        <ModalLayout title={title} >
        <div >

            
            <div dangerouslySetInnerHTML={{__html: text}} className=" mb-5  prose max-w-max  ">

            </div>
            
        </div>

        <div className=" py-4 flex items-center space-x-4 justify-end">
            <button type="button" onClick={() => props.setModalIsOpen(false)} className="flex items-center   text-gray-600 h-12  px-10 py-1 focus:outline-none  space-x-4 bg-gray-100 rounded-md border border-gray-200 hover:bg-gray-200">
                <span className="truncate">Zavřít</span>
                 
            </button>
        </div>
        </ModalLayout>
        </>
    )
}