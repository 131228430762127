import React, { useState } from "react";  
import ConfirmDialog from './dialog/confirm'; 
import {Disclosure } from "@headlessui/react";  
import {Modal} from './layout/modal';  
import {MiniCart} from './cart/miniCart';  
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { NavLink } from "react-router-dom"; 
import Logo from '../assets/proart.png'
import User from "./layout/user"; 

export const cities = [  
  {name: 'Brno', link: '/kurzy/brno' },
  {name: 'Jičín', link: '/kurzy/jicin' },
  {name: 'Praha', link: '/kurzy/praha' },
  {name: 'Plzeň', link: '/kurzy/plzen' },
  {name: 'Slavonice', link: '/kurzy/slavonice' },
 ]
 

const mainTitle = 'ProART Festival ';
 

export default function Layout(props) {
 
  const Component = props.component
  const [cartOpen, setCartOpen] = useState(); 
  const [title, setTitle] = useState();  
  const [modalIsOpen,setModalIsOpen] = useState(false); 
  const [dialogIsOpen,setDialogIsOpen] = useState(false); 
  const [printIsOpen,setPrintIsOpen] = useState(false); 
  const [slideModalIsOpen,setSlideModalIsOpen] = useState(false); 
  const [slideModalContent,setSlideModalContent] = useState(false); 
  const [modalContent,setModalContent] = useState(false); 
  const [dialogContent,setDialogContent] = useState(false); 
  const [toastOff, setToastOff] = useState(false);
  const [eanActive, setEanActive] = useState(false);
  const [formId, setFormId] = useState(false); 
  
 
  function modalClose() {
    setModalIsOpen(false);
    setTimeout(() => {
      setModalContent(false);
    }, 600);
    setToastOff(false);
  }

  function dialogClose() {
    setDialogIsOpen(false);
    setTimeout(() => {
      setDialogContent(false);
    }, 200);
    
  }
 

  function slideModalClose() {
    setSlideModalIsOpen(false);
    setTimeout(() => {
      setSlideModalContent(false);
    }, 600);
    setToastOff(false);
  }

  function showCart() {
    cartOpen ? (
      setCartOpen(false)
    ) : (
      setCartOpen(true)
    )
  };     
 
 
    return(
        <>
   
      <Disclosure as="nav" className="bg-blue-800 bg-opacity-10 fixed w-full top-0 z-50 shadow-md border-b border-white">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 ">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <NavLink to={'/'}>
                    <img
                      className="h-12"
                      src={Logo}
                      alt="ProART"
                    />
                    </NavLink>
                  </div>
                  <div className="hidden md:flex">
                    <div className="hidden sm:-my-px sm:ml-12 sm:flex sm:space-x-4  h-16">
                     {props.location.pathname !== '/' && props.cities.map((item, itemIdx) =>
                         
                          <NavLink  key={itemIdx} to={'/kurzy/' + item.alias} activeClassName="border-blue-600 text-blue-600 opacity-100 font-bold " className="inline-flex items-center px-1 pt-1 border-b-4 text-base   border-transparent text-blue-800 opacity-70 hover:border-gray-300 hover:opacity-80  ">
                              {item.name}
                          </NavLink>
                        
                      )}
                    </div>
                  </div>
                </div>
                <div className=" ">
                  <div className="ml-4 flex items-center md:ml-6">
                      <div className="relative">
                        <User {...props} />
                      </div>
                      <MiniCart {...props} />
                    

                    
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-blue-600 inline-flex items-center justify-center p-2 rounded-md text-indigo-200 hover:text-white hover:bg-blue-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-600 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="z-50 md:hidden bg-gray-100">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {props.cities.map((item, itemIdx) =>
                <Disclosure.Button key={itemIdx} onClick={() => setTitle(mainTitle + item.name)} className="grid w-full">
                   <NavLink  to={'/kurzy/' + item.alias} activeClassName="bg-blue-800 text-white" className=" hover:bg-blue-500 hover:bg-opacity-75 block px-3 py-2 rounded-md text-base font-medium text-left ">
                      {item.name}
                  </NavLink> 
                  </Disclosure.Button>
                )}
              </div>
                
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <main className=" py-6 sm:px-6 lg:px-8 w-full overflow-y-auto mt-16 " style={{height: "calc(100vh - 4rem)"}}>
         <div className="max-w-7xl mx-auto">
          
              <Component {...props} cartOpen={cartOpen} showCart={showCart} setDialogContent={setDialogContent}  
              setDialogIsOpen={setDialogIsOpen}  dialogIsOpen={dialogIsOpen}
              setToastOff={setToastOff} toastOff={toastOff} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} modalClose={modalClose}
              slideModalIsOpen={slideModalIsOpen} setSlideModalIsOpen={setSlideModalIsOpen} slideModalClose={slideModalClose}
              eanActive={eanActive} setEanActive={setEanActive} 
              modalContent={modalContent}
              setModalContent={setModalContent}
              dialogClose={dialogClose} 
              setTitle={setTitle}
              printIsOpen={printIsOpen}
              formId={formId}
              setFormId={setFormId}
              mainTitle={mainTitle} 
              title={title}
            />
        </div>
      </main> 
        <Modal {...props}  modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} modalClose={modalClose} modalContent={modalContent}  response={props.response}
          setModalContent={setModalContent}
          setDialogContent={setDialogContent}  
          dialogClose={dialogClose} 
          setDialogIsOpen={setDialogIsOpen}  dialogIsOpen={dialogIsOpen}
          printIsOpen={printIsOpen}
          formId={formId}
          eanActive={eanActive} setEanActive={setEanActive} 
          setFormId={setFormId}
          setToastOff={setToastOff}
           /> 
    
       
    <ConfirmDialog {...props}  dialogIsOpen={dialogIsOpen} setDialogIsOpen={setDialogIsOpen} dialogContent={dialogContent} dialogClose={dialogClose} />

    </>
    )
}