 

import { ChevronRightIcon, XIcon } from "@heroicons/react/outline";

function CartItemslist({cartItems,deleteItem,updateItem,lastCartItem,summary}) {
    
    
    return(
        <div className="divide-y divide-gray-300 "> 
        
        { cartItems && cartItems.length > 0 &&  
            <>  
                {cartItems.map(item => (
                <div key={item.id} className={"grid grid-cols-4 lg:grid-cols-6 w-full text-gray-800 items-center  pin-r pin-y  pt-3 px-3 pb-4 " + (lastCartItem && lastCartItem === item?.itemId ? "bg-blue-600 bg-opacity-10 " : "")} cellSpacing="0">
                   
                    
                    <div className={"flex space-x-2 lg:space-x-0 justify-between " + (item.type !== 'cart_promo' && item.type !== 'eorder' ? summary ? "col-span-3 lg:col-span-5 " : "col-span-2 lg:col-span-4" : 
                    summary ? "col-span-2 lg:col-span-5 " : "col-span-2 lg:col-span-5")} >
                        <div>
                            <p className="font-medium ">{item.name}</p>

                            {item.pack_items && 
                            <ul className="my-2">
                            {item.pack_items.map((item,i) => (
                                <li key={i} className="text-sm flex">
                                    <ChevronRightIcon className="w-5 text-gray-500 mr-1" />
                                    {item.mod_kurzy_nazev}
                                </li>
                            ))}
                            </ul>
                            }
                            <p className="text-xs text-gray-500">{item.note}</p>
                        </div>
                        
                        
                     
                    </div> 
                        {item.type !== 'cart_promo' && item.type !== 'eorder' &&
                            <>
                    <div className="col-span-2 lg:col-span-1 flex flex-row items-center lg:justify-center text-gray-400 ">
                            <button type="button" onClick={() => updateItem(item.id,parseInt(item.count)-1)} className="focus:outline-none hover:text-gray-900">
                                <svg className="h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </button>
                            <span className="inline-block text-center font-medium w-8  text-gray-800 ">{item.count}</span>
                            <button type="button" onClick={() => updateItem(item.id,parseInt(item.count)+1)} className="focus:outline-none hover:text-gray-900">
                                <svg  className="h-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </button>
                    </div> 
                            </>
                        }
                  
                        <div className="col-span-2 lg:col-span-1 flex justify-end items-center space-x-5 ">
                            <span className=" font-medium">
                                {parseFloat(item.price).toLocaleString('cs')} Kč
                            </span>
                            {!summary && 
                            <div className=" justify-end items-center lg:flex" >
                                <button type="button" onClick={() => deleteItem(item.id)} className="text-gray-400 hover:text-gray-900 focus:outline-none py-3">
                                    <XIcon className="w-6 text-gray-500"  />
                                </button>
                            </div>
                             }
                        </div> 
                   
                </div>
                ))}
            </>
       
       
        
        } 
        <div />
    </div>
    )

}

export default CartItemslist;