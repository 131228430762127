import React, { useState, useEffect, Fragment } from "react";  
import { ChevronRightIcon } from '@heroicons/react/outline'
import { NavLink } from "react-router-dom"; 

export default function Home(props) {
    useEffect(() => {  
       props.setTitle('Přihláška ProART Festival')
        // eslint-disable-next-line
      }, []); 
   
        return (
             <> 
             <div className="max-w-6xl mx-auto">
 
              <div className=" grid gap-4 grid-cols-2 lg:grid-cols-5 justify-center p-5">

                {props.cities.map((item, itemIdx) =>
                  <NavLink key={itemIdx} to={'kurzy/' + item.alias} activeClassName="bg-gray-900 " className="text-white hover:bg-blue-700 hover:text-white p-3 px-5  text-xl rounded-md mx-auto bg-blue-600 w-full flex items-center justify-between ">
                      <span>{item.name}</span>
                      <ChevronRightIcon className="w-6" />
                  </NavLink>

                  )}
                  
              </div>
                {props.info && 
                  <>
                  <div className="grid lg:grid-cols-2 p-5 gap-10">

                    <div className="prose " dangerouslySetInnerHTML={{__html: props?.info['prihlaska-home']}} >

                    </div>
                    <div>
                      {props?.img && <img src={props.img} alt="ProART Festival" className="rounded-md" />}
                    </div>
                  </div>
                  </>
                }
              </div>
            </>

        )
    }
 